<template>
  <validation-observer ref="resetPasswordFormObserver">
    <ife-container id="main">
      <ife-row align-h="center">
        <ife-col
          cols="12"
          md="7"
          lg="5"
          class="mt-4"
        >
          <ife-card
            v-if="complete"
            title="Thank you"
          >
            <p>
              You have successfully reset your IFE Data password.
            </p>

            <p>
              <router-link :to="{name: 'login'}">
                Click here to log into IFE Data
              </router-link>
            </p>
          </ife-card>
          <b-overlay
            v-else
            :show="loading"
          >
            <ife-card
              title="Reset your password"
            >
              <b-alert
                v-if="error"
                variant="danger"
                show
              >
                {{ error }}
              </b-alert>
              <ife-form @submit="submit">
                <validation-provider
                  v-slot="v"
                  vid="password"
                  name="password"
                  :rules="
                    {
                      required: true,
                      regex: passwordRegex
                    }"
                >
                  <b-form-group label="New Password">
                    <b-form-input
                      v-model="password"
                      :state="getValidationState(v)"
                      type="password"
                    />
                    <b-form-invalid-feedback>
                      {{ v.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot="v"
                  rules="required|confirm:@password"
                >
                  <b-form-group label="Confirm New Password">
                    <b-form-input
                      v-model="confirmPassword"
                      :state="getValidationState(v)"
                      type="password"
                    />
                    <b-form-invalid-feedback>
                      {{ v.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-btn
                  size="sm"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </b-btn>
              </ife-form>
            </ife-card>
          </b-overlay>
        </ife-col>
      </ife-row>
    </ife-container>
  </validation-observer>
</template>

<script>
import Regex from '@/Client/Store/Regex';

export default {
  name: 'ResetPassword',
  data() {
    return {
      complete: false,
      confirmPassword: null,
      error: null,
      loading: false,
      password: null,
      passwordRegex: Regex.password,
      token: null,
    };
  },
  mounted() {
    if (!this.$route.query.token) {
      this.$router.push({name: 'login'});
    }

    this.token = this.$route.query.token;
  },
  methods: {
    submit() {
      const isValid = this.$refs.resetPasswordFormObserver.validate();
      if (!isValid) {
        return;
      }

      this.loading = true;
      this.error = null;

      this.axios.post(
          `${process.env.CORE_API_URL}/reset-password/${this.token}`,
          {
            password: this.password,
            verify: this.confirmPassword,
            token: this.token,
          },
      )
          .then(() => {
            this.complete = true;
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              this.error = error.response.data.error;
              return;
            }

            this.error = 'Something went wrong, please try again.';
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>

</style>
