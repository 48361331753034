<template>
  <ife-container>
    <ife-row align-h="center">
      <ife-col
        cols="12"
        md="7"
        lg="5"
        class="mt-4"
      >
        <b-overlay :show="states.login.loading">
          <ife-card title="Login">
            <b-alert
              variant="danger"
              :show="states.login.error !== null"
            >
              {{ states.login.error }}
            </b-alert>

            <validation-observer v-slot="v">
              <ife-form
                @submit="v.handleSubmit(login)"
              >
                <ife-simple-input
                  v-model="credentials.email"
                  :label-cols="4"
                  label="Email Address"
                  type="email"
                  rules="required"
                />

                <ife-simple-input
                  v-model="credentials.password"
                  :label-cols="4"
                  label="Password"
                  type="password"
                  rules="required"
                />

                <b-btn
                  size="sm"
                  variant="primary"
                  type="submit"
                >
                  Log In
                </b-btn>
              </ife-form>
            </validation-observer>
            <login-links />
          </ife-card>

          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="shield-lock"
                font-scale="3"
                animation="cylon"
              />
              <p id="label">
                Authenticating
              </p>
            </div>
          </template>
        </b-overlay>
      </ife-col>
    </ife-row>
  </ife-container>
</template>

<script>
import {InitStore} from '@/Client/Store';
import UserPreferences from '@/Client/Model/UserPreferences';
import LoginLinks from '@/Client/Component/Common/LoginLinks.vue';

export default {
  name: 'Login',
  components: {LoginLinks},
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      states: {
        login: {
          loading: false,
          error: null,
        },
      },
    };
  },
  methods: {
    login() {
      this.states.login.error = null;
      this.states.login.loading = true;
      this.$store.dispatch(
          'login',
          this.credentials,
      ).then(() => {
        InitStore.init(this.axios, this.$store);
        UserPreferences.removeCompanyLogin();
      }).catch((error) => {
        this.$log.error(error);
        this.states.login.loading = false;
        this.states.login.error = 'Invalid email or password';
      });
    },
  },
};
</script>

<style scoped>

</style>
