<template>
  <ife-container
    id="main"
    :class="cssClass"
    fluid
  >
    <ife-row align-h="center">
      <ife-col
        cols="12"
        md="7"
        lg="5"
        class="mt-4"
      >
        <ife-card
          v-if="submitted"
          title="Thank you"
          :class="`ife-card ${cssClass}`"
        >
          Your request has been received and will be reviewed.
        </ife-card>
        <ife-card
          v-else
          title="Request Access"
          :class="`ife-card ${cssClass}`"
        >
          <b-overlay :show="requested">
            <validation-observer v-slot="v">
              <ife-form
                @submit="v.handleSubmit(submit)"
              >
                <ife-simple-input
                  v-model="request.email"
                  :label-cols="12"
                  :label-cols-lg="4"
                  :label-cols-md="12"
                  :label-cols-sm="12"
                  label="Email Address"
                  type="email"
                  rules="required|max:180"
                />
                <ife-simple-input
                  v-model="request.name"
                  :label-cols="12"
                  :label-cols-lg="4"
                  :label-cols-md="12"
                  :label-cols-sm="12"
                  label="Name"
                  type="text"
                  rules="required|max:250"
                />
                <ife-simple-input
                  v-model="request.companyName"
                  :label-cols="12"
                  :label-cols-lg="4"
                  :label-cols-md="12"
                  :label-cols-sm="12"
                  label="Company"
                  type="text"
                  rules="required|max:250"
                />
                <ife-simple-input
                  v-model="request.jobTitle"
                  :label-cols="12"
                  :label-cols-lg="4"
                  :label-cols-md="12"
                  :label-cols-sm="12"
                  label="Job Title"
                  type="text"
                  rules="required|max:250"
                />
                <ife-simple-input
                  v-model="request.airlineClient"
                  :label-cols="12"
                  :label-cols-lg="4"
                  :label-cols-md="12"
                  :label-cols-sm="12"
                  label="Airline Client"
                  type="text"
                  rules="max:200"
                />

                <!-- Privacy policy link needed here when ready -->

                <b-btn
                  size="sm"
                  variant="primary"
                  type="submit"
                >
                  Request Access
                </b-btn>
              </ife-form>
            </validation-observer>
          </b-overlay>
        </ife-card>
      </ife-col>
    </ife-row>
  </ife-container>
</template>

<script>
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  name: 'AccessRequest',
  data() {
    return {
      request: {
        email: null,
        name: null,
        companyName: null,
        jobTitle: null,
        airlineClient: null,
      },
      submitted: false,
      requested: false,
    };
  },
  computed: {
    companyLogin() {
      return UserPreferences.getCompanyLogin();
    },
    cssClass() {
      return this.hasCompanyLogin ? this.companyLogin.cssClass : '';
    },
    hasCompanyLogin() {
      return null !== this.companyLogin;
    },
  },
  beforeMount() {
    this.redirectIfRequestNotEnabled();
  },
  methods: {
    redirectIfRequestNotEnabled() {
      if (
        !this.hasCompanyLogin ||
          (Object.hasOwn(this.companyLogin, 'requestAccountEnabled') &&
          !this.companyLogin.requestAccountEnabled)
      ) {
        this.$router.push({name: 'login'});
      }
    },
    submit() {
      if (null === UserPreferences.getCompanyLogin()) {
        this.toastError('Unable to submit request at this time. Please try again later');
        return;
      }

      this.requested = true;
      this.axios.post(`${process.env.CORE_API_URL}/request-access`, {
        ...this.request,
        company: UserPreferences.getCompanyLogin()?.id ?? null,
      })
          .then(() => {
            this.submitted = true;
          })
          .catch(() => {
            this.toastError('Something went wrong, please try again later');
          })
          .finally(() => {
            this.requested = false;
          });
    },
  },
};
</script>

<style scoped>

</style>
